import React from "react";
import { WcStack } from "@bpipartners/webui";
import { AppContext } from "@guberno/utils/context";
import { UserForm } from "./forms/UserForm";
import { createUserDefaultValues } from "../utils/createUserValues";
import {
  IamUser,
  TenantAllUserTenant,
  GetAllUserTenantsDocument,
} from "@guberno/data-access";
import { useForm } from "react-hook-form";
import { setCookie } from "nookies";
import { TenantAutoComplete } from "@guberno/composite/tenant";
import { useQuery } from "@apollo/client";
import axios from "axios";

export const UserProfile = () => {
  const { localAppState } = React.useContext(AppContext);
  const { data: tenantsData } = useQuery(GetAllUserTenantsDocument, {
    fetchPolicy: "network-only",
  });
  //@ts-ignore
  const [tenant, setTenant] = React.useState(localAppState.userData.tenantID);
  const [tenantList, setTenantList] = React.useState<TenantAllUserTenant[]>([]);
  const form = useForm<IamUser>({
    mode: "onSubmit",
    //@ts-ignore
    defaultValues: createUserDefaultValues(localAppState.userData),
  });

  React.useEffect(() => {
    if (
      tenantsData &&
      tenantsData.AllUserTenants &&
      tenantsData.AllUserTenants.length > 0
    ) {
      setTenantList(tenantsData.AllUserTenants);
    }
  }, [tenantsData]);

  const handleTenantSelected = (val: TenantAllUserTenant) => {
    if (val) {
      setTenant(val.TenantID);

      axios
        .post(
          "/auth/switchTenant",
          { newTenantID: val.TenantID },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          if (res && res.data && res.data.Status === 200) {
            window.location.href = "/";
          } else {
            console.log("Failed to switch tenant, please try again. Error: ");
          }
        })
        .catch((FEerror: any) => {
          //@ts-ignore
          if (FEerror.response.status === 404) setExpiredResetLink(true);
        });
      // setCookie(null, "guberno-t", val.TenantID, {
      //   SameSite: "Strict",
      // });
    }
  };

  return (
    <WcStack id="UserProfile">
      {tenantList && tenantList.length > 0 && (
        <WcStack padding={5} spacing={2}>
          <TenantAutoComplete
            width={300}
            inputParam={tenant}
            tenants={tenantList}
            onValueChanged={handleTenantSelected}
          />
        </WcStack>
      )}
      <WcStack id="UserModalStack">
        <UserForm
          rolesList={localAppState.userData?.roles}
          form={form}
          formDisabled={true}
        />
      </WcStack>
    </WcStack>
  );
};
