import React from "react";
import {
  WcButton,
  WcStack,
  WcTypography,
  WcLink,
  Box,
  useTheme,
  WcPaper,
} from "@bpipartners/webui";
import { useTranslations } from "next-intl";
import { LoginForm, LoginFormState } from "../../forms/login";
import axios from "axios";
import { TenantAutoComplete } from "@guberno/composite/tenant";
import { TenantAllUserTenant } from "@guberno/data-access";

export const LoginComposite = () => {
  const t = useTranslations();
  const loginRef = React.useRef();
  const theme = useTheme();
  const [failedLogin, setFailedLogin] = React.useState<boolean>(false);
  const [multipleTenants, setMultipleTenants] = React.useState<boolean>(false);
  const [tenant, setTenant] = React.useState("");
  const [tenantList, setTenantList] = React.useState<TenantAllUserTenant[]>([]);
  const handleLoginDetails = (val: LoginFormState) => {
    if (val) {
      axios
        .post(
          "/auth/login",
          { username: val.userName, password: val.password },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          if (res && res.data && res.data.Status === 200) {
            setFailedLogin(false);
            if (res.data && res.data.multipleTenants) {
              setTenant(res.data.tenantID);
              setTenantList(res.data.Tenants);
              setMultipleTenants(true);
            } else {
              window.location.href = "/";
            }
          } else {
            setFailedLogin(true);
          }
        })
        .catch((FEerror: any) => {
          console.log("FE error", FEerror);
          setFailedLogin(true);
        });
    }
  };

  const handleLoginClicked = () => {
    if (loginRef && loginRef.current) {
      //@ts-ignore
      loginRef.current.onSubmit();
    }
  };

  const handleTenantSelected = (val: TenantAllUserTenant) => {
    if (val) {
      axios
        .post(
          "/auth/switchTenant",
          { newTenantID: val.TenantID },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          if (res && res.data && res.data.Status === 200) {
            window.location.href = "/";
          } else {
            console.log("Failed to switch tenant, please try again. Error: ");
          }
        })
        .catch((FEerror: any) => {
          //@ts-ignore
          if (FEerror.response.status === 404) setExpiredResetLink(true);
        });
    }
  };

  return (
    <WcPaper>
      <WcStack
        data-cy="loginComp"
        alignItems="center"
        height="500px"
        width="500px"
        mt={"80px"}
        id="LoginComposite"
      >
        <WcStack margin={14} direction={"row"} spacing={2}>
          <Box
            component="img"
            sx={{
              height: 60,
            }}
            //@ts-ignore
            alt="Capensis logo"
            src="https://storage.googleapis.com/capensis-pub/Capensis-Logo_Gradient.png"
          />
        </WcStack>
        {!multipleTenants && (
          <WcStack alignItems={"center"} padding={4}>
            <WcStack
              m={4}
              spacing={8}
              justifyContent={"center"}
              alignItems="center"
              width="100%"
            >
              <WcTypography variant="h5">{t("login.loginNovus")}</WcTypography>
              <WcTypography variant="body2">
                {t("common.fillDetails")}
              </WcTypography>
            </WcStack>
            {failedLogin && (
              <WcTypography
                variant="body1"
                data-cy="failedLoginText"
                //@ts-ignore
                color={theme.appColors.error}
              >
                {t("login.invalidCredentials")}
              </WcTypography>
            )}
            <WcStack
              justifyContent={"center"}
              alignItems="center"
              width="100%"
              mt={8}
            >
              <LoginForm onLoginDetails={handleLoginDetails} ref={loginRef} />
            </WcStack>

            <WcStack spacing={2} mt={4}>
              <WcStack alignItems={"center"}>
                <WcButton
                  data-cy="loginButton"
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={handleLoginClicked}
                >
                  {t("login.login")}
                </WcButton>
              </WcStack>
            </WcStack>
            <WcStack spacing={2} padding={2} mt={8}>
              <WcLink href="/forgotPassword">
                <WcTypography
                  variant="body2"
                  //@ts-ignore
                  color={theme.palette.primary.main}
                >
                  {t("login.forgotPassword")}
                </WcTypography>
              </WcLink>
            </WcStack>
          </WcStack>
        )}

        {multipleTenants && tenantList && tenantList.length > 0 && tenant && (
          <WcStack alignItems={"center"} padding={4}>
            <WcStack
              m={4}
              spacing={8}
              justifyContent={"center"}
              alignItems="center"
              width="100%"
            >
              <WcTypography variant="body2">
                {t("login.selectTenant")}
              </WcTypography>
            </WcStack>

            <WcStack
              justifyContent={"center"}
              alignItems="center"
              width="100%"
              mt={8}
            >
              <TenantAutoComplete
                width={300}
                inputParam={tenant}
                tenants={tenantList}
                onValueChanged={handleTenantSelected}
              />
            </WcStack>
          </WcStack>
        )}
      </WcStack>
    </WcPaper>
  );
};

export default LoginComposite;
